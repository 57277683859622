<template>
  <div>
    <!-- 活动周标题 -->
    <top :islist="islist"></top>
    <!-- 模块标题 -->
    <div class="category">
      <div class="title">
        <strong><span>新闻•</span>中心</strong>
      </div>
      <div class="subTitle"><span>News Center</span></div>
    </div>
    <div class="content-wrapper">
      <!-- 轮播 -->
      <el-carousel trigger="click">
        <el-carousel-item v-for="(item,index) in slideArr" :key="index">
        <router-link :to="`/news/${item.jid}`">
          <div class="text">
            <p>{{item.theme}}</p>
          </div>
          <img :src="item.photo">
        </router-link>
        </el-carousel-item>
      </el-carousel>
      <!-- 列表 -->
      <ul class="list">
        <li v-for="item in list" :key="item.jid" @click="goDetail(item.jid)">
          <p>{{item.theme}}</p>
        </li>
      </ul>
    </div>
    <pagination
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
    <page-footer></page-footer>
  </div>
</template>

<script>
import Top from '@/views/mobile/components/Top.vue';
import PageFooter from './components/PageFooter.vue';
import Pagination from './components/Pagination.vue';
import { getNewsList, newsSlide } from '@/api/mobile.js';
export default {
  name: 'MNews',
  components: {
    Top,
    PageFooter,
    Pagination
  },
  data() {
    return {
      islist: true,
      list:[],
      total:0,
      listQuery:{
        page:1,
        limit:10
      },
      slideArr:[],
      isPageHide:false
    };
  },
  created(){
    this.getList()
    this.getNewsSlide()
  },
   mounted(){
    window.addEventListener('pageshow', function(){      
      if(this.isPageHide) {
          window.location.reload();
      }
  });
  window.addEventListener('pagehide', function(){     
      this.isPageHide = true;
  });
  },
  methods:{
    getList(){
      let params ={
        page:this.listQuery.page,
        pageSize:this.listQuery.limit
      }
      getNewsList(params).then(res => {
        this.total = res.data.total
        this.list = res.data.list
      })
    },
    goDetail(id){
      this.$router.push('/news/'+id)
    },
    getNewsSlide(){
      newsSlide().then(res => {
        this.slideArr = res.data
      })
    }
  }
};
</script>

<style scoped lang="scss">
// 分类标题
.category {
  text-align: center;
  position: relative;
}
.category::after {
  display: block;
  content: '';
  width: 460px;
  height: 1px;
  background-color: #1046a2;
  position: absolute;
  z-index: -1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.category .title {
  font-size: 36px;
  color: #333;
}
.category .title strong span {
  color: #1046a2;
}
.category .title strong {
  padding: 0 40px;
  background-color: #fff;
}
.category .subTitle {
  font-size: 20px;
  color: #333;
}
.category .subTitle span {
  padding: 0 10px;
  background-color: #fff;
}
.content-wrapper {
  padding: 30px;
}
// 轮播
.el-carousel{
  height: 490px;
  overflow: hidden;
}
.el-carousel .text{
  position: absolute;
  bottom: 52px;
  left: 0;
  line-height: 40px;
  right: 0;
  z-index: 1;
  line-height: 80px;
  background-color: #2978fe;
  color: #fff;
}
.el-carousel .text p{
  font-size: 30px;
  padding-left: 20px;
  padding-right: 150px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.el-carousel img{
  width: 100%;
  height: auto;
}
::v-deep .el-carousel__button{
  display: block;
  opacity: 1;
  width: 14px;
  height: 14px;
  background-color: #fff;
  border-radius: 7px;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  transition: .3s;
  cursor: pointer;
}
::v-deep .el-carousel__indicators--horizontal{
 bottom: 0;
 left: 590px;
 transform: translateX(0);
} 
::v-deep .el-carousel__indicator.is-active button {
    opacity: 1;
    background-color: #323332;
}
// 列表
.list li {
  border-bottom: 1px solid #ccc;
  color: #333;
  font-size: 28px;
  line-height: 80px;
}
.list li p{ 
  height: 80px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
