<template>
  <div style="background: #f4f4f7">
    <Nav ref="navchild"></Nav>
    <div class="banner-width">
      <Banner></Banner>
    </div>
    <div class="mainwidth">
      <Title>
        <template v-slot:one>
          <el-divider content-position="center">新闻中心</el-divider>
        </template>
        <template v-slot:two>
          <span class="sub1">News Center</span>
        </template>
      </Title>
      <div class="list">
        <div
          class="tuitem"
          @click="gotonewsdetails(item.jid)"
          v-if="index <= 0"
          v-for="(item, index) in list"
          :key="index + '1'"
        >
          <div class="left"><img :src="item.photo" /></div>

          <div class="right">
            <h4>{{ item.theme }}</h4>
            <div class="txt">{{ item.intro }}</div>

            <div class="time">{{ item.uploadTime }}</div>
          </div>
        </div>

        <div
          class="item"
          v-for="(item, index) in list"
          :key="index"
          @click="gotonewsdetails(item.jid)"
        >
          <div>{{ item.theme }}</div>
          <span>{{ item.uploadTime }}</span>
        </div>
      </div>

      <div class="page">
        <el-pagination
          background
          :page-size="listPage.pageSize"
          layout="prev, pager, next"
          :current-page="listPage.currentPage"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :total="listPage.totalCount"
        ></el-pagination>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Nav from "@/views/pc/components/Nav";
import Banner from "@/views/pc/components/Banner";
import Footer from "@/views/pc/components/Footer";
import Title from "@/views/pc/components/Title";
import { newsList } from "@/api/pc.js";
export default {
  name: "News",
  data() {
    return {
      listPage: {
        // pageSize代表每页显示的数目
        pageSize: 6,
        // currentPage代表当面的页数
        currentPage: 1,
        // totalCount列表的总数
        totalCount: 10,
      },
      list: [{}],
    };
  },

  created() {
    this.getNewsList();
  },

  mounted() {
    var arr = this.$route.path.split("/");
    this.$refs.navchild.setNavIndex("/" + arr[1]);
  },
  methods: {
    // pageSize 改变时会触发的事件
    handleSizeChange(val) {
      this.listPage.pageSize = val;
      // 当pageSize发生变化后需重新查询列表
      this.getNewsList();
    },
    // currentPage 改变时会触发
    handleCurrentChange(val) {
      this.listPage.currentPage = val;
      // 当currentPage发生变化后需重新查询列表
      this.getNewsList();
    },
    getNewsList() {
      let params = {
        page: this.listPage.currentPage,
        pageSize: this.listPage.pageSize,
      };
      newsList(params).then((res) => {
        this.list = res.data.list;
        console.log(res);
        this.listPage.totalCount = res.data.total;
      });
    },

    gotonewsdetails(id) {
      console.log(id);
      this.$router.push(`/news/${id}`);
    },
  },
  components: { Footer, Nav, Title, Banner },
};
</script>
<style scoped>
.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  background: #fff;
}
</style>

<style scoped lang="scss">
.page {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}
.sub1 {
  background: none;
  color: #999;
}
// .el-divider__text {
//   background: #fff;
// }
.banner-width {
  background: url("~@/assets/pcimg/bg1.jpg") center no-repeat;
}

.mainwidth {
  width: 1200px;
  margin: 0 auto;

  .list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 20px;
    .tuitem {
      border-bottom: #e8e8e8 solid 1px;
      padding-bottom: 40px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      .right {
        width: 850px;
        h4 {
          text-align: left;
          font-size: 24px;
          color: #333;
          padding-top: 30px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .txt {
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden; //溢出内容隐藏
          text-overflow: ellipsis; //文本溢出部分用省略号表示
          display: -webkit-box; //特别显示模式
          -webkit-line-clamp: 2; //行数
          line-clamp: 2;
          -webkit-box-orient: vertical; //盒子中内容竖直排列
          line-height: 35px;

          color: #999;
          padding-top: 30px;
          font-size: 16px;
        }
        .time {
          color: #999;
          padding-top: 30px;
          font-size: 16px;
        }
      }
      .left {
        width: 315px;
        height: 230px;

        img {
          width: 100%;
          height: 100%;
          display: block;
          object-fit: cover;
        }
      }
    }
    .item {
      height: 70px;
      line-height: 70px;
      font-size: 16px;
      display: flex;
      justify-content: space-between;
      color: #333;
      border-bottom: #e8e8e8 solid 1px;
      width: 100%;
      cursor: pointer;
      div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 70%;
      }
      span {
        color: #999;
        display: block;
      }
    }
  }
}
</style>